import React from "react";
import AppsIcon from "@material-ui/icons/Apps";
import StorageIcon from "@material-ui/icons/Storage";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import PrintIcon from "@material-ui/icons/Print";
import ExplicitIcon from "@material-ui/icons/Explicit";
import DeckIcon from "@material-ui/icons/Deck";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import BookIcon from "@material-ui/icons/Book";
import ComputerIcon from "@material-ui/icons/Computer";
import ToysIcon from "@material-ui/icons/Toys";
import VpnLockIcon from "@material-ui/icons/VpnLock";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import CasinoIcon from "@material-ui/icons/Casino";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import HealingIcon from "@material-ui/icons/Healing";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import WatchIcon from "@material-ui/icons/Watch";
import PetsIcon from "@material-ui/icons/Pets";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import SportsIcon from "@material-ui/icons/Sports";
import ViewStreamIcon from "@material-ui/icons/ViewStream";
import BuildIcon from "@material-ui/icons/Build";
import TwitterIcon from "@material-ui/icons/Twitter";
import GitHubIcon from "@material-ui/icons/GitHub";

export const icons = {
  apps: <AppsIcon />,
  hosts: <StorageIcon />,
  "non-profits": <MoneyOffIcon />,
  "3d-printing": <PrintIcon />,
  adult: <ExplicitIcon />,
  "appliances-furniture": <DeckIcon />,
  art: <ArtTrackIcon />,
  books: <BookIcon />,
  electronics: <ComputerIcon />,
  "cryptocurrency-paraphernalia": <ToysIcon />,
  "domains-hosting-vpns": <VpnLockIcon />,
  education: <CastForEducationIcon />,
  fashion: <EmojiPeopleIcon />,
  food: <FastfoodIcon />,
  gambling: <CasinoIcon />,
  "gift-cards": <CardGiftcardIcon />,
  "health-household": <HealingIcon />,
  "holiday-travel": <FlightTakeoffIcon />,
  jewelry: <WatchIcon />,
  pets: <PetsIcon />,
  services: <RoomServiceIcon />,
  "software-video-games": <VideogameAssetIcon />,
  sports: <SportsIcon />,
  streaming: <ViewStreamIcon />,
  tools: <BuildIcon />,
  twitter: <TwitterIcon />,
  github: <GitHubIcon />
}
